<template>
  <div class="content-right rounded-3">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="{ name: 'personal-dashboard' }"
            >Dashboard</router-link
          >
        </li>
        <li class="breadcrumb-item">
          <router-link :to="{ name: 'my-leaves' }">My Leaves</router-link>
        </li>
        <li class="breadcrumb-item active">Request Leave</li>
      </ol>
    </nav>
    <div
      class="content-body"
      id="fullHeight"
      style="min-height: calc(100vh - 135px)"
    >
      <div class="row align-items-center">
        <div class="col-5">
          <h2 class="title2">Request Leave</h2>
        </div>
        <div class="col text-end">
          <router-link
            :to="{ name: 'my-leaves' }"
            class="btn btn-outline-primary"
            >Back</router-link
          >
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div v-if="this.$store.state.loader">
            <loader
              object="#f74b3d"
              color1="#ffffff"
              color2="#17fd3d"
              size="5"
              speed="2"
              bg="#343a40"
              objectbg="#999793"
              opacity="80"
              disableScrolling="false"
              name="spinning"
            ></loader>
          </div>
          <div v-else class="card">
            <div class="card-header">
              <flash-message class="myCustomClass"></flash-message>
            </div>
            <div class="card-body">
              <div v-if="this.$store.state.loader">
                <loader
                  object="#f74b3d"
                  color1="#ffffff"
                  color2="#17fd3d"
                  size="5"
                  speed="2"
                  bg="#343a40"
                  objectbg="#999793"
                  opacity="80"
                  disableScrolling="false"
                  name="spinning"
                ></loader>
              </div>
              <form v-else @submit.prevent="onSubmit">
                <div class="row">
                  <div class="col-md-6">
                    <div class="card">
                      <div class="card-body">
                        <div class="mb-3">
                          <label class="form-label">Leave Group</label>
                          <b-form-select
                            class="form-select"
                            :class="{
                              'form-group--error': $v.form.type.$error,
                            }"
                            aria-label="Default select example"
                            v-model="$v.form.type.$model"
                            :validator="$v.form.type"
                            :options="this.leaveTypes"
                            @change="setLeaveType"
                          ></b-form-select>
                          <span class="error" v-if="$v.form.type.$error == true"
                            >Leave Group is required</span
                          >
                        </div>
                        <div class="row mb-3 request-leave">
                          <div class="col-md-12">
                            <label class="form-label">Leave Type</label>
                            <div class="form-group">
                              <b-form-radio
                                id="half_day"
                                v-model="form.leave_type"
                                type="radio"
                                value="HALF DAY"
                                required
                                >Half day</b-form-radio
                              >
                              <b-form-radio
                                id="full_day"
                                v-model="form.leave_type"
                                type="radio"
                                value="FULL DAY"
                                required
                                >Full day</b-form-radio
                              >
                            </div>
                          </div>
                          <p
                            class="error"
                            v-if="$v.form.leave_type.$error == true"
                          >
                            Leave Type is required
                          </p>
                        </div>
                        <div class="row">
                          <div class="mb-3 col-md-6">
                            <label class="form-label">Leave From</label>
                            <datepicker
                              :typeable="false"
                              placeholder="Select Date"
                              :format="customFormatter"
                              :highlighted="highlighted"
                              :highlightDates="dates"
                              :disabled-dates="disabledDates"
                              class="form-control"
                              @input="halfDayLeave()"
                              v-model="$v.form.leavefrom.$model"
                              :validator="$v.form.leavefrom"
                              @opened="disableLastMonth()"
                            ></datepicker>

                            <span
                              class="error"
                              v-if="$v.form.leavefrom.$error == true"
                              >Leave from is required</span
                            >
                          </div>
                          <div class="mb-3 col-md-6">
                            <label class="form-label">Start Time</label>
                            <div>
                              <vue-timepicker
                                format="hh:mm A"
                                v-model="$v.form.start_time.$model"
                                :validator="$v.form.start_time"
                                auto-scroll
                                @change="
                                  timeChangeHandler($event, 'start_time')
                                "
                              ></vue-timepicker>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="mb-3 col-md-6">
                            <label class="form-label">Leave To</label>
                            <datepicker
                              :typeable="false"
                              placeholder="Select Date"
                              :format="customFormatter"
                              :highlighted="highlighted"
                              class="form-control"
                              v-model="$v.form.leaveto.$model"
                              :validator="$v.form.leaveto"
                              @input="dayCount()"
                              :disabled-dates="disabledDatesPicker"
                            ></datepicker>
                            <span
                              class="error"
                              v-if="$v.form.leaveto.$error == true"
                              >Leave to is required</span
                            >
                          </div>
                          <div class="mb-3 col-md-6">
                            <label class="form-label">End Time</label>
                            <div>
                              <vue-timepicker
                                format="hh:mm A"
                                v-model="$v.form.end_time.$model"
                                :validator="$v.form.end_time"
                                auto-scroll
                                @change="timeChangeHandler($event, 'end_time')"
                              ></vue-timepicker>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="mb-3 col-md-6">
                            <label class="form-label">Return Date</label>
                            <datepicker
                              :typeable="false"
                              placeholder="Select Date"
                              :format="customFormatter"
                              :highlighted="highlighted"
                              class="form-control"
                              v-model="$v.form.returndate.$model"
                              :validator="$v.form.returndate"
                              :disabled-dates="disabledReturnDates"
                            ></datepicker>
                            <span
                              class="error"
                              v-if="$v.form.returndate.$error == true"
                              >Return Date is required</span
                            >
                          </div>
                          <div class="mb-3 col-md-6">
                            <label class="form-label">Leaves Days</label>
                            <div>
                              <input
                                type="text"
                                v-model="form.leaveCount"
                                class="form-control"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="mb-3">
                          <label class="form-label">Reason</label>
                          <textarea
                            class="form-control"
                            v-model="$v.form.reason.$model"
                            :validator="$v.form.reason"
                          ></textarea>
                          <span
                            class="error"
                            v-if="$v.form.reason.$error == true"
                            >Reason is required</span
                          >
                        </div>
                        <div class="text-right">
                          <button
                            v-if="
                              this.$store.state.permittedActions.includes(551)
                            "
                            type="button"
                            @click="saveAsDraft()"
                            class="btn btn-outline-primary space"
                          >
                            Mark as Draft
                          </button>
                          <button
                            type="submit"
                            class="btn btn-outline-primary space"
                          >
                            Submit Request
                          </button>
                          <router-link
                            :to="{ name: 'my-leaves' }"
                            class="btn btn-outline-primary"
                            >Cancel</router-link
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";
import axios from "axios";
export default {
  components: { Datepicker, VueTimepicker },
  data() {
    return {
      form: {
        typeid: null,
        type: null,
        leave_type: null,
        leavefrom: null,
        start_time: "10:00 AM",
        end_time: "07:00 PM",
        leaveto: null,
        returndate: null,
        reason: null,
        action: "final",
        leaveCount: null,
      },
      highlighted: {
        includeDisabled: true,
        dates: [new Date("yyyy-MM-dd")],
      },
      disabledDates: { days: [6, 0] },
      disabledDatesPicker: {
        to: new Date(Date.now() - 8640000),
        days: [6, 0],
      },
      disabledReturnDates: {
        to: new Date(Date.now() - 8640000),
        days: [6, 0],
      },
    };
  },
  validations: {
    form: {
      type: {
        required,
      },
      leavefrom: {
        required,
      },
      start_time: {},
      leave_type: {
        required,
      },
      end_time: {},
      leaveto: {
        required,
      },
      returndate: {
        required,
      },
      reason: {
        required,
      },
    },
  },
  mounted() {
    this.$store.dispatch("switchLoader", true);
    this.$store.dispatch("loadMyLeaves");
    this.$store.dispatch("loadHolidaysdata");
    this.$store.dispatch("loadSpecialWorkingDays");
  },
  computed: {
    leaveTypes() {
      this.disableLastMonth();
      return this.$store.state.myLeavesData.lt.map((type) => {
        return {
          value: type.leavetype,
          text: type.leavetype,
        };
      });
    },
  },
  methods: {
    disableLastMonth() {
      var currentYear = new Date().getFullYear();
      var currentMonth = new Date().getMonth();
      console.log(currentYear, currentMonth);
      this.disabledDates.to = new Date(currentYear, 0, 1);
    },
    halfDayLeave() {
      if (this.form.leavefrom != "" && this.form.leave_type == "FULL DAY") {
        this.disabledDatesPicker.to = new Date(this.form.leavefrom);
        this.disabledDatesPicker.from = "";
        this.disabledReturnDates.to = new Date(this.form.leavefrom);
      }
      if (this.form.leave_type == "HALF DAY" && this.form.leavefrom != "") {
        this.form.leaveCount = 0.5;
        var leaveFrom = this.form.leavefrom;
        var leaveTo = (this.disabledDatesPicker.from = new Date(leaveFrom));
        this.disabledDatesPicker.to = new Date(leaveFrom);
        this.form.leaveto = leaveTo;
        //this.form.returndate = leaveFrom;
        this.disabledReturnDates.to = new Date(leaveFrom);
      } else if (
        this.form.leave_type == "FULL DAY" &&
        this.form.leavefrom != ""
      ) {
        this.form.leaveto = "";
        this.form.returndate = "";
        this.disabledDatesPicker.to = new Date(this.form.leavefrom);
      }
    },
    dayCount() {
      if (this.form.leave_type == "FULL DAY" && this.form.leavefrom != "") {
        this.disabledReturnDates.to = new Date(this.form.leaveto);
        if (new Date(this.form.leaveto) > new Date(this.form.returndate)) {
          this.form.returndate = null;
        }
        var start = new Date(this.form.leavefrom);
        var end = new Date(this.form.leaveto);
        var leavesDaysCount = [];
        var specialWorkingDayCount = [];
        var specialWorkingDays = this.$store.state.specialWorkingDays.data;
        var finalSpecialWorkingDays = specialWorkingDays.map(function (
          specialWorkingDate
        ) {
          return moment(specialWorkingDate.date).format("yyyy-MM-DD");
        });
        for (
          var leavesDay = start;
          leavesDay <= end;
          leavesDay.setDate(leavesDay.getDate() + 1)
        ) {
          var leaveDays = moment(leavesDay).format("yyyy-MM-DD");
          if (finalSpecialWorkingDays.indexOf(leaveDays) != -1) {
            specialWorkingDayCount.push(leaveDays);
          }
          if (leavesDay.getDay() == 0 || leavesDay.getDay() == 6) {
            continue;
          }
          var count = new Date(
            leavesDay.getFullYear(),
            leavesDay.getMonth(),
            leavesDay.getDate()
          );
          leavesDaysCount.push(count);
        }
        var leavesDays = leavesDaysCount.map(function (leavedates) {
          return moment(leavedates).format("yyyy-MM-DD");
        });
        var holidayData = this.$store.state.holidaysData;
        var finalHolidayData = holidayData.map(function (dateval) {
          var data = new Date(dateval.date);
          return moment(data).format("yyyy-MM-DD");
        });
        let intersection = finalHolidayData.filter((x) =>
          leavesDays.includes(x)
        );
        var leavedaysCount = leavesDaysCount.length - intersection.length;
        this.form.leaveCount = leavedaysCount + specialWorkingDayCount.length;
      }
    },

    timeChangeHandler(event, time) {
      if (time == "start_time") {
        this.form.start_time = event.displayTime;
      } else {
        this.form.end_time = event.displayTime;
      }
    },
    customFormatter(date) {
      return moment(date).format("yyyy-MM-DD ");
    },
    setLeaveType() {
      let lt = this.$store.state.myLeavesData.lt.filter((type) => {
        return (type.leavetype = this.form.type);
      })[0];
      this.form.typeid = lt.id;
    },
    saveAsDraft() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      this.form.action = "draft";
      this.onSubmit();
    },
    onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      axios
        .post("/personal/leaves/request", this.form)
        .catch(({ response }) => {
          console.log(response);
          this.flashMessage.error({
            message: "Something went wrong!",
            time: 3000,
          });
        })
        .then(({ data }) => {
          console.log(data);
          if (data.success) {
            this.flashMessage.success({
              message: data.success,
              time: 3000,
            });
          }
          this.$router.push({ name: "my-leaves" });
        });
    },
  },
};
</script>
